import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box, IconButton, InputAdornment, Modal, Paper } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleVerifyEmail = async () => {
    try {
      const response = await axios.post("https://dealerdemo.unitedpetroleumlanka.com/be/api/user/verifyEmail", { email });
      if (response.status === 200) {
        setVerified(true);
        setErrorMessage("");
      }
    } catch (error) {
      setErrorMessage("Email not found.");
    }
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };

  const handleToggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword((prevShowConfirmNewPassword) => !prevShowConfirmNewPassword);
  };

  const navigate = useNavigate();
  //const [openModal, setOpenModal] = useState(false);

  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      return "Password must contain both letters and digits.";
    }
    return "";
  };

  const handleSaveNewPassword = async () => {
    if (newPassword === confirmNewPassword) {
      const validationError = validatePassword(newPassword);
      if (validationError) {
        setErrorMessage(validationError);
      } else {
        try {
          const response = await axios.post("https://dealerdemo.unitedpetroleumlanka.com/be/api/user/reset", {
            email,
            newPassword
          });
          if (response.status === 200) {
            setErrorMessage("");
            setOpenSuccessModal(true);
            setTimeout(() => {
              navigate("/login");
            }, 1000);
          }
        } catch (error) {
          setErrorMessage("Error resetting password.");
        }
      }
    } else {
      setErrorMessage("Passwords do not match.");
    }
  };

  return (
    <Container
      sx={{
        backgroundColor: "#10153F",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Poppins, sans-serif"
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          fontFamily: "Poppins, sans-serif",
          maxWidth: 400
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "95%",
            height: "auto",
            mt: 3,
            p: 2,
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "rgba(201, 227, 242, 0.14)",
            borderRadius: 3,
            border: "1px solid #0078C1"
          }}
        >
          <IconButton
            onClick={() => navigate("/login")}
            sx={{
              mr: 45,
              color: "#FFFFFF",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ color: "#FFFFFF", mt: 3, fontFamily: "Poppins, sans-serif" }}>
            Reset Password
          </Typography>
          {!verified ? (
            <Box
              sx={{
                display: "flex",
                mt: 5,
                mb: 5,
                flexDirection: "column",
                gap: 2,
                fontFamily: "Poppins, sans-serif"
              }}
            >
              <Box mb={2} position="relative">
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#ffffff",
                    position: "absolute",
                    top: -5,
                    fontSize: 14,
                    padding: "0 4px"
                  }}
                >
                  Email
                </Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  placeholder="Enter your email here"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                  InputLabelProps={{
                    style: {
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif"
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon style={{ color: "#FFFFFF" }} />
                      </InputAdornment>
                    ),
                    style: { color: "#FFFFFF" }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF"
                      },
                      "&:hover fieldset": {
                        borderColor: "#0078C1"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0078C1"
                      }
                    }
                  }}
                />
              </Box>
              {errorMessage && (
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 3,
                  mb: 2,
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                <Button
                  onClick={handleVerifyEmail}
                  fullWidth
                  variant="contained"
                  disabled={!email}
                  sx={{
                    borderRadius: "7px",
                    backgroundColor: "#0078C1",
                    textTransform: "none",
                    color: "#FFFFFF",
                    width: "150px",
                    height: "50px",
                    fontFamily: "Poppins, sans-serif",
                    "&:hover": {
                      backgroundColor: "#1f3544"
                    }
                  }}
                >
                  Verify Email
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                mt: 3,
                mb: 5,
                flexDirection: "column",
                gap: 2,
                fontFamily: "Poppins, sans-serif"
              }}
            >
              <Box mb={2} position="relative">
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#ffffff",
                    position: "absolute",
                    top: -5,
                    fontSize: 14,
                    padding: "0 4px"
                  }}
                >
                  Email
                </Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  id="verifiedEmail"
                  name="verifiedEmail"
                  value={email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon style={{ color: "#FFFFFF" }} />
                      </InputAdornment>
                    ),
                    style: { color: "#FFFFFF" },
                    readOnly: true
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF"
                      },
                      "&:hover fieldset": {
                        borderColor: "#0078C1"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0078C1"
                      }
                    }
                  }}
                />
              </Box>
              <Box mb={2} position="relative">
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#ffffff",
                    position: "absolute",
                    top: -5,
                    fontSize: 14,
                    padding: "0 4px"
                  }}
                >
                  New Password
                </Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  id="newPassword"
                  placeholder="Enter your new password"
                  name="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  InputLabelProps={{
                    style: {
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif"
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "#FFFFFF" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleToggleNewPasswordVisibility} edge="end" style={{ color: "#FFFFFF" }}>
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { color: "#FFFFFF" }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF"
                      },
                      "&:hover fieldset": {
                        borderColor: "#0078C1"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0078C1"
                      }
                    }
                  }}
                />
              </Box>

              <Box mb={2} position="relative">
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#ffffff",
                    position: "absolute",
                    top: -5,
                    fontSize: 14,
                    padding: "0 4px"
                  }}
                >
                  Confirm New Password
                </Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  id="confirmNewPassword"
                  placeholder="Confirm your new password"
                  name="confirmNewPassword"
                  type={showConfirmNewPassword ? "text" : "password"}
                  value={confirmNewPassword}
                  onChange={handleConfirmNewPasswordChange}
                  InputLabelProps={{
                    style: {
                      color: "#FFFFFF",
                      fontFamily: "Poppins, sans-serif"
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ color: "#FFFFFF" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleToggleConfirmNewPasswordVisibility} edge="end" style={{ color: "#FFFFFF" }}>
                          {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { color: "#FFFFFF" }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#FFFFFF"
                      },
                      "&:hover fieldset": {
                        borderColor: "#0078C1"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0078C1"
                      }
                    }
                  }}
                />
              </Box>

              {errorMessage && (
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 3,
                  mb: 2,
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                <Button
                  onClick={handleSaveNewPassword}
                  fullWidth
                  variant="contained"
                  sx={{
                    borderRadius: "7px",
                    backgroundColor: "#0078C1",
                    textTransform: "none",
                    color: "#FFFFFF",
                    width: "150px",
                    height: "50px",
                    fontFamily: "Poppins, sans-serif",
                    "&:hover": {
                      backgroundColor: "#1f3544"
                    }
                  }}
                >
                  Save Password
                </Button>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              mt: 5,
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
          </Box>
        </Paper>
      </Box>
      {/* Success Modal */}
      <Modal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            borderRadius: 2,
            backgroundColor: "#10153F",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4,
            textAlign: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 2
            }}
          >
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h6" color="success" sx={{ textAlign: "center" }}>
              Password changed!
            </Typography>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default ResetPassword;
