import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppBar, Typography, Toolbar, Button, Drawer, List, IconButton, ListItem, ListItemText, ListItemIcon, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TodayIcon from "@mui/icons-material/Today";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HistoryIcon from "@mui/icons-material/History";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Navbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    navigate("/login", { replace: true });
  };

  const getPageTitle = (pathname) => {
    switch (pathname) {
      case "/dashboard":
        return "Daily Usage";
      case "/order":
        return "Order";
      case "/history":
        return "Order History";
      case "/profile":
        return "Profile";
      default:
        return "Page";
    }
  };

  const handleOrderRequestClick = async () => {
    toggleDrawer();

    try {
      const token = localStorage.getItem("token");

      const response = await axios.get("https://dealer.unitedpetroleumlanka.com/be/api/item/items", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      navigate("/order");
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  const handleProfileClick = async () => {
    toggleDrawer();

    try {
      const token = localStorage.getItem("token");

      const response = await axios.get("https://dealer.unitedpetroleumlanka.com/be/api/bank", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      navigate("/profile");
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#10153F",
          minHeight: "130px",
          borderRadius: "0 0 24px 24px",
          margin: 0,
          fontFamily: "Poppins, sans-serif"
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Button color="inherit" onClick={toggleDrawer}>
            <MenuIcon sx={{ fontSize: 30 }} />
          </Button>
          <Box>
            <IconButton color="inherit"></IconButton>
            <IconButton component={Link} to="/profile" color="inherit">
              <AccountCircleIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </Box>
        </Toolbar>

        <Box sx={{ padding: 1, textAlign: "center" }}>
          <Typography variant="h6" sx={{ fontFamily: "Poppins, sans-serif", color: "white" }}>
            {getPageTitle(location.pathname)}
          </Typography>
        </Box>
      </AppBar>
      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
        <List
          sx={{
            backgroundColor: "#10153F",
            height: "100%",
            color: "white",
            paddingTop: "20px",
            fontFamily: "Poppins, sans-serif"
          }}
        >
          <ListItem
            sx={{
              justifyContent: "center",
              marginBottom: "30px",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <Link
              to="/order"
              onClick={toggleDrawer}
              style={{
                textDecoration: "none",
                fontFamily: "Poppins, sans-serif"
              }}
            >
              <img
                src="/united-petroleum-logo.png"
                alt="Logo"
                style={{
                  height: "30px",
                  width: "auto",
                  fontFamily: "Poppins, sans-serif"
                }}
              />
            </Link>
          </ListItem>
          <ListItem button onClick={handleOrderRequestClick}>
            <ListItemIcon sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
              <AddShoppingCartIcon />
            </ListItemIcon>
            <ListItemText
              primary="Order"
              primaryTypographyProps={{
                color: "white",
                fontSize: "1.2rem",
                fontFamily: "Poppins, sans-serif"
              }}
            />
          </ListItem>

          <ListItem component={Link} to="/dashboard" onClick={toggleDrawer} button>
            <ListItemIcon sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
              <TodayIcon />
            </ListItemIcon>
            <ListItemText
              primary="Daily Usage"
              primaryTypographyProps={{
                color: "white",
                fontSize: "1.2rem",
                fontFamily: "Poppins, sans-serif"
              }}
            />
          </ListItem>

          <ListItem component={Link} to="/history" onClick={toggleDrawer} button>
            <ListItemIcon sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText
              primary="Order History"
              primaryTypographyProps={{
                color: "white",
                fontSize: "1.2rem",
                fontFamily: "Poppins, sans-serif"
              }}
            />
          </ListItem>
          <ListItem button onClick={handleProfileClick}>
            <ListItemIcon sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary="Profile"
              primaryTypographyProps={{
                color: "white",
                fontSize: "1.2rem",
                fontFamily: "Poppins, sans-serif"
              }}
            />
          </ListItem>

          <ListItem
            button
            sx={{ marginTop: "260px", fontFamily: "Poppins, sans-serif" }}
            onClick={() => {
              toggleDrawer();
              handleLogout();
            }}
          >
            <ListItemIcon sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{
                color: "white",
                fontSize: "1.2rem",
                fontFamily: "Poppins, sans-serif"
              }}
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
