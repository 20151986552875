import React from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorModal = ({ open, onClose, title, message, titleColor = "red" }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "600px",
          borderRadius: 2,
          backgroundColor: "#10153F",
          color: "#FFFFFF",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#FFFFFF",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="h2"
          sx={{
            mb: 2,
            color: titleColor,
            fontFamily: "Poppins, sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ErrorOutlineIcon sx={{ mr: 1 }} />
          {title}
        </Typography>
        <Typography
          sx={{
            mb: 2,
            color: "white",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {message}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
