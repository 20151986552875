// src/components/ErrorPage.js

import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "50px",
        color: "#00308F",
        marginTop: "200px",
      }}
    >
      <h1>500 Internal Server Error</h1>
      <p>Oops! Something went wrong on our side.</p>
      <p>Please try again later.</p>
      <img
        src="/united-petroleum-logo.png"
        alt="United Petroleum"
        style={{ width: "200px", maxWidth: "100%", marginTop: "250px" }}
      />
    </div>
  );
};

export default ErrorPage;
