import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, Modal, IconButton, TextField, Grid } from "@mui/material";
import axios from "axios";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const OrderHistory = () => {
  const [userID, setUserId] = useState("");
  const [orders, setOrders] = useState([]);
  const [creditOrders, setCreditOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }
      setUserId(userID);
      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/order/orders/${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // Filter orders based on date
        const today = new Date();
        const cutoffDate = new Date(today.setDate(today.getDate() - 65));

        const filteredOrders = response.data?.data.filter((order) => new Date(order.date) >= cutoffDate).sort((a, b) => new Date(b.date) - new Date(a.date));

        setOrders(filteredOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchCreditOrders = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");
    
      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }
      setUserId(userID);
      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/order/creditOrders/${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        const today = new Date();
        const cutoffDate = new Date(today.setDate(today.getDate() - 65));

        const filteredCreditOrders = response.data?.data
          ?.filter((creditOrder) => new Date(creditOrder.date) >= cutoffDate)
          .sort((a, b) => new Date(b.date) - new Date(a.date));
    
        setCreditOrders(filteredCreditOrders);
      } catch (error) {
        console.error("Error fetching credit orders:", error);
      }
    };

    fetchOrders();
    fetchCreditOrders();
  }, [userID]);

  const handleOpenOrderDetails = (order) => {
    setSelectedOrder(order);
  };

  const handleCloseOrderDetails = () => {
    setSelectedOrder(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const combinedOrders = [...(orders || []), ...(creditOrders || [])].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const filteredOrders = combinedOrders.filter((order) => {
    const orderIdMatch = order.orderId?.toLowerCase().includes(searchQuery.toLowerCase());
    const dateMatch = new Date(order.date).toLocaleDateString().includes(searchQuery);
    const amountMatch = order.totalAmount?.toString().includes(searchQuery);
    return orderIdMatch || dateMatch || amountMatch;
  });

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const formatPrice = (price) => {
    const number = parseFloat(price);
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const formatDateTime = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    const time = d.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit"
    });
    return `${day}-${month}-${year} ${time}`;
  };

  return (
    <Box sx={{ mt: 4 }}>
      <TextField
        variant="outlined"
        placeholder="Search "
        value={searchQuery}
        onChange={handleSearchChange}
        InputLabelProps={{
          style: { color: "#02344D", fontFamily: "Poppins, sans-serif" }
        }}
        sx={{
          marginTop: 15,
          mb: 2,
          fontFamily: "Poppins, sans-serif",
          width: "92%",
          "& .MuiOutlinedInput-root": {
            borderRadius: "13px"
          },
          "& .MuiOutlinedInput-input": {
            borderRadius: "13px"
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />

      {filteredOrders.map((order, index) => (
        <Card
          key={index}
          sx={{
            mb: 3,
            backgroundColor: "#CEEDFF",
            width: "95%",
            mx: "auto",
            fontFamily: "Poppins, sans-serif",
            boxShadow: 3,
            borderRadius: 2,
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.02)"
            }
          }}
          onClick={() => handleOpenOrderDetails(order)}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              padding: 3,
              textAlign: "left",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: "Poppins, sans-serif" }}>
                {order.orderId ? `Order ID: ${order.orderId}` : `Credit Order ID: ${order.creditOrderId}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  color: "text.secondary",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                Order Date: {formatDateTime(order.date)}
              </Typography>
              {order.deliveryDate && (
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    color: "text.secondary",
                    fontFamily: "Poppins, sans-serif"
                  }}
                >
                  Delivery Date: {order.deliveryDate}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  mt: 1,
                  fontWeight: "medium",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                Total Price: Rs.{formatPrice(order.totalAmount)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}

      <Modal open={!!selectedOrder} onClose={handleCloseOrderDetails} aria-labelledby="order-details-modal-title" aria-describedby="order-details-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#10153F",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: "80%",
            maxWidth: 500,
            fontFamily: "Poppins, sans-serif",
            overflow: "auto"
          }}
        >
          <IconButton
            onClick={handleCloseOrderDetails}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: "#FFFFFF"
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedOrder && (
            <>
              <Typography id="order-details-modal-title" variant="h6" component="h2" sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}>
                Order Details
              </Typography>

              {/* Order Date on the left and Order ID on the right, with nowrap to prevent wrapping */}
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={7} sx={{ textAlign: "left" }}>
                  <Typography variant="body1">
                    <strong>Order Date:</strong> {formatDateTime(selectedOrder.date)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    textAlign: "right",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  <Typography variant="body1">
                    <strong>Order ID:</strong> {selectedOrder.orderId}
                  </Typography>
                </Grid>
              </Grid>

              {/* Delivery Date aligned to the left */}
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                  <Typography variant="body1">
                    <strong>Delivery Date:</strong> {selectedOrder.deliveryDate}
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: "left" }}>
                  <Typography variant="body1">
                    <strong>Delivery Method:</strong> {selectedOrder.deliveryMethod}
                  </Typography>
                </Grid>

                {/* Conditionally render Bowser No. if delivery method is "Own Pickup" */}
                {selectedOrder.deliveryMethod === "Own Pickup" && (
                  <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Typography variant="body1">
                      <strong>Bowser No:</strong> {selectedOrder.bowserNo}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              {/* Delivery Method aligned to the left */}
              {/* <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">
                  <strong>Delivery Method:</strong> {selectedOrder.deliveryMethod}
                </Typography>
              </Grid>
            </Grid> */}

              {/* Items list aligned to the left */}
              <Box sx={{ mb: 1 }}>
                {selectedOrder.items.map((item, index) => (
                  <Grid container key={index} spacing={0} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left"
                      }}
                    >
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {capitalizeFirstLetter(item.itemName)}: {item.quantity}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>

              {/* Total Price aligned to the left */}
              <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: "left", mt: 5 }}>
                <strong>Total Price:</strong> Rs.
                {formatPrice(selectedOrder.totalAmount)}
              </Typography>
            </>
          )}
          <Box
            sx={{
              mt: 5,
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderHistory;
