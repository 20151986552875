/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button, Box, IconButton, InputAdornment, Paper, CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";

const autofillStyle = css`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
`;

const LoginPage = () => {
  const [formData, setFormData] = useState({
    userID: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post("https://dealerdemo.unitedpetroleumlanka.com/be/api/user/login", {
        no: formData.userID,
        password: formData.password
      });

      const { token } = response?.data?.data;

      localStorage.setItem("token", token);
      localStorage.setItem("userID", formData.userID);

      const userID = formData.userID;

      localStorage.getItem(token);

      // await axios.put(
      //   `https://dealerdemo.unitedpetroleumlanka.com/be/api/customers/credit/${userID}`,
      //   {},
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`
      //     }
      //   }
      // );

      await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/item/items`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/bowser`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      await axios.post(
        "https://dealerdemo.unitedpetroleumlanka.com/be/api/transaction/transactions",
        {
          userCode: formData.userID
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLoading(false);
      window.location.href = "/order";
    } catch (error) {
      setLoading(false);
      // navigate("/-error500");
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message || "An error occurred.");
      } else {
        setErrorMessage("An error occurred.");
      }
    }
  };

  return (
    <Container
      sx={{
        backgroundColor: "#10153F",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Poppins, sans-serif"
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          fontFamily: "Poppins, sans-serif",
          maxWidth: 400
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "95%",
            height: "auto",
            mt: 3,
            p: 2,
            backgroundColor: "rgba(201, 227, 242, 0.14)",
            borderRadius: 3,
            border: "1px solid #0078C1",
            fontFamily: "Poppins, sans-serif"
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#FFFFFF",
              marginBottom: 3,
              mt: 3,
              fontFamily: "Poppins, sans-serif"
            }}
          >
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              mt: 5,
              mb: 5,
              flexDirection: "column",
              fontFamily: "Poppins, sans-serif",
              gap: 2
            }}
          >
            <Box mb={2} position="relative">
              <Typography
                variant="subtitle2"
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  top: -25,
                  fontSize: 14,
                  padding: "0 4px",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                User ID
              </Typography>

              <TextField
                name="userID"
                variant="outlined"
                fullWidth
                placeholder="User ID"
                autoFocus
                value={formData.userID}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: "#FFFFFF",
                    fontFamily: "Poppins, sans-serif"
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon style={{ color: "#FFFFFF" }} />
                    </InputAdornment>
                  ),
                  style: {
                    color: "#FFFFFF",
                    backgroundColor: "transparent",
                    fontFamily: "Poppins, sans-serif"
                  }
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "13px",
                    backgroundColor: "transparent",
                    "& fieldset": { borderColor: "#FFFFFF" },
                    "&:hover fieldset": { borderColor: "#FFFFFF" },
                    "&.Mui-focused fieldset": { borderColor: "#FFFFFF" },
                    "&:hover .MuiOutlinedInput-input": {
                      backgroundColor: "transparent"
                    },
                    "&.Mui-focused .MuiOutlinedInput-input": {
                      backgroundColor: "transparent"
                    }
                  },
                  "& label.Mui-focused": {
                    color: "#FFFFFF"
                  }
                }}
                css={autofillStyle}
              />
            </Box>

            <Box mb={2} position="relative">
              <Typography
                variant="subtitle2"
                style={{
                  color: "#ffffff",
                  position: "absolute",
                  top: -8,
                  fontSize: 14,
                  padding: "0 4px",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                Password
              </Typography>
              <TextField
                margin="normal"
                fullWidth
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                value={formData.password}
                onChange={handleChange}
                InputLabelProps={{ style: { color: "#FFFFFF" } }}
                InputProps={{
                  style: {
                    color: "#FFFFFF",
                    backgroundColor: "transparent",
                    fontFamily: "Poppins, sans-serif"
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon style={{ color: "#FFFFFF" }} />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility}>{showPassword ? <Visibility sx={{ color: "#FFFFFF" }} /> : <VisibilityOff sx={{ color: "#FFFFFF" }} />}</IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "13px",
                    backgroundColor: "transparent",
                    "& fieldset": { borderColor: "#FFFFFF" },
                    "&:hover fieldset": { borderColor: "#FFFFFF" },
                    "&.Mui-focused fieldset": { borderColor: "#FFFFFF" }
                  }
                }}
                css={autofillStyle}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,

                fontFamily: "Poppins, sans-serif"
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: "7px",
                  backgroundColor: "#0078C1",
                  textTransform: "none",
                  color: "#FFFFFF",
                  width: "150px",
                  height: "50px",
                  fontFamily: "Poppins, sans-serif",
                  "&:hover": {
                    backgroundColor: "#1f3544"
                  }
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} sx={{ color: "#FFFFFF" }} /> : "Login"}
              </Button>
            </Box>
          </Box>
          {errorMessage && (
            <Typography variant="body1" sx={{ color: "red", mt: 2 }}>
              {errorMessage}
            </Typography>
          )}
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Link
              to="/reset"
              style={{
                color: "#FFFFFF",
                textDecoration: "none",
                fontFamily: "Poppins, sans-serif"
              }}
            >
              <Typography variant="body2" fontFamily="Poppins, sans-serif">
                Forgot Password?
              </Typography>
            </Link>
          </Box>

          <Box
            sx={{
              mt: 5,
              textAlign: "center",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            <img src="/united-petroleum-logo.png" alt="United P" style={{ width: 100, height: "auto" }} />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default LoginPage;
