import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useInactivityTimeout = (timeoutDuration = 480000) => {
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;

    

    const handleUserActivity = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        localStorage.removeItem("token");
        navigate("/login");
      }, timeoutDuration);
    };

    const events = ["mousemove", "mousedown", "keypress", "scroll", "touchstart"];

    events.forEach(event => window.addEventListener(event, handleUserActivity));

    // Initial setup
    handleUserActivity();

    return () => {
      events.forEach(event => window.removeEventListener(event, handleUserActivity));
      clearTimeout(timeoutId);
    };
  }, [navigate, timeoutDuration]);
};

export default useInactivityTimeout;
