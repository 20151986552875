import React from 'react';
import { Modal, Paper, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessModal = ({
  open,
  onClose,
  message = "Action completed successfully!",  // Default success message
  icon = <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />,  // Default icon
  iconColor = "success",   // Icon color (can be customized)
  messageColor = "success",  // Message color (can be customized)
  paperSx = {},  // Additional styles for Paper component
  boxSx = {}     // Additional styles for Box component
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          borderRadius: 2,
          backgroundColor: "#10153F",
          color: "#FFFFFF",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
          ...paperSx,  // Allows for additional custom styles from parent
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
            ...boxSx,   // Allows for additional custom styles from parent
          }}
        >
          {icon}
          <Typography
            variant="h6"
            color={messageColor}
            sx={{ textAlign: "center" }}
          >
            {message}
          </Typography>
        </Box>
      </Paper>
    </Modal>
  );
};

export default SuccessModal;
