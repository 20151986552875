import React, { useState, useEffect } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Grid, Card, CardContent, Typography, Container, TextField, Box, IconButton, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import fuel from "dayjs";
import { NumericFormat } from "react-number-format";

const Dashboard = () => {
  const [userID, setUserId] = useState("");
  const [isEditable, setIsEditable] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [fuelData, setFuelData] = useState([]);
  const [stockAmounts, setStockAmounts] = useState({});
  const [salesAmounts, setSalesAmounts] = useState({});
  const [receivedStockAmounts, setReceivedStockAmounts] = useState({});
  const [lastEdited, setLastEdited] = useState({});
  const [numberFormatState, setNumberFormatState] = useState("");

  useEffect(() => {
    const today = new Date();
    const dateString = today.toLocaleDateString();
    setCurrentDate(dateString);
    const storedUserId = localStorage.getItem("userID");
    setUserId(storedUserId);

    const fetchFuelData = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }

      try {
        // Fetching items data
        const response = await axios.get("https://dealerdemo.unitedpetroleumlanka.com/be/api/item/items/all", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const items = response.data?.data;

        // Initializing maps with default values
        const stockMap = {};
        const salesMap = {};
        const receivedStockMap = {};
        const lastEditedMap = {};

        items.forEach((item) => {
          stockMap[item.no] = "";
          salesMap[item.no] = "";
          receivedStockMap[item.no] = "";
          lastEditedMap[item.no] = "Not yet edited";
        });

        try {
          // Fetching daily usage data
          const usageResponse = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/daily/dailyUsage/${userID}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const dailyUsage = usageResponse.data.dailyUsage;

          // Mapping dailyUsage data to items
          items.forEach((item) => {
            const usageItem = dailyUsage.items.find((u) => u.itemName === item.description);
            if (usageItem) {
              stockMap[item.no] = usageItem.stockAmount;
              salesMap[item.no] = usageItem.salesAmount;
              receivedStockMap[item.no] = usageItem.receivedStockAmount;
              lastEditedMap[item.no] = usageItem.lastEdited;
            }
          });
        } catch (error) {
          console.warn("No daily usage data found:", error.message);
        }

        setFuelData(items);
        setStockAmounts(stockMap);
        setSalesAmounts(salesMap);
        setReceivedStockAmounts(receivedStockMap);
        setLastEdited(lastEditedMap);
        setIsEditable(items.map(() => false));
      } catch (error) {
        console.error("Error fetching fuel data:", error);
      }
    };

    fetchFuelData();
  }, []);

  const handleEditClick = (index) => {
    const newEditableState = [...isEditable];
    newEditableState[index] = !newEditableState[index];
    setIsEditable(newEditableState);
  };

  const handleSaveClick = async () => {
    const timestamp = new Date().toISOString();

    const updatedLastEdited = { ...lastEdited };
    fuelData.forEach((fuel, index) => {
      if (isEditable[index]) {
        updatedLastEdited[fuel.no] = timestamp;
      }
    });

    const payload = {
      userID,
      usage: fuelData.map((fuel, index) => {
        const lastEditedValue = updatedLastEdited[fuel.no];
        return {
          itemName: fuel.description,
          stockAmount: Number(stockAmounts[fuel.no]) || 0,
          salesAmount: Number(salesAmounts[fuel.no]) || 0,
          receivedStockAmount: Number(receivedStockAmounts[fuel.no]) || 0,
          ...(lastEditedValue !== "Not yet edited" && {
            lastEdited: lastEditedValue
          })
        };
      })
    };

    // Additional validation before sending payload
    const validatePayload = (payload) => {
      return payload.usage.every((item) => {
        return (
          typeof item.itemName === "string" &&
          !isNaN(item.stockAmount) &&
          !isNaN(item.salesAmount) &&
          !isNaN(item.receivedStockAmount) &&
          (item.lastEdited ? !isNaN(Date.parse(item.lastEdited)) : true)
        );
      });
    };

    if (!validatePayload(payload)) {
      console.error("Payload validation failed:", payload);
      return;
    }

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post("https://dealerdemo.unitedpetroleumlanka.com/be/api/daily/dailyUsage", payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Update the lastEdited state after successful save
      setLastEdited(updatedLastEdited);
    } catch (error) {
      console.error("Error updating fuel data:", error.response ? error.response.data : error.message);
    }

    // Reset edit mode for all items
    setIsEditable(fuelData.map(() => false));
  };

  const handleStockChange = (index, value) => {
    const newStockAmounts = { ...stockAmounts };
    newStockAmounts[fuelData[index].no] = value;
    setStockAmounts(newStockAmounts);
  };

  const handleSalesChange = (index, value) => {
    const newSalesAmounts = { ...salesAmounts };
    newSalesAmounts[fuelData[index].no] = value;
    setSalesAmounts(newSalesAmounts);
  };

  const handleReceivedStockChange = (index, value) => {
    const newReceivedStockAmounts = { ...receivedStockAmounts };
    newReceivedStockAmounts[fuelData[index].no] = value;
    setReceivedStockAmounts(newReceivedStockAmounts);
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const parseDate = (dateStr) => {
    // Handle ISO date string
    if (dateStr.includes("-")) {
      const [day, month, year] = dateStr.split("-").map(Number);
      if (year && month && day) {
        return new Date(year, month - 1, day);
      }
    }

    // Handle standard ISO date string (e.g., "2024-08-30")
    return new Date(dateStr);
  };
  const formatDate = (date) => {
    const d = parseDate(date);
    if (isNaN(d.getTime())) return "Invalid date"; // Handle invalid dates
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100%",
        width: "100%",
        marginTop: 18,
        backgroundColor: "#FFFFFF"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          marginBottom: 2
        }}
      >
        <Typography fontFamily="Poppins, sans-serif" variant="h6" textAlign="left">
          Date: {formatDate(currentDate)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {fuelData.map((fuel, index) => (
          <Grid item xs={12} md={6} key={fuel.no}>
            <Card sx={{ backgroundColor: "#CEEDFF" }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Typography variant="h6" fontFamily="Poppins, sans-serif">
                    {capitalizeFirstLetter(fuel.description)}
                  </Typography>
                  {!isEditable[index] && (
                    <IconButton onClick={() => handleEditClick(index)} sx={{ ml: 1, position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          width: 35,
                          height: 35,
                          backgroundColor: "#0078C1",
                          borderRadius: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 1
                        }}
                      />
                      <EditIcon
                        sx={{
                          zIndex: 2,
                          position: "relative",
                          color: "white"
                        }}
                      />
                    </IconButton>
                  )}
                </Box>{" "}
                <Typography variant="h6" fontFamily="Poppins, sans-serif">
                  {fuel.type}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <NumericFormat
                    customInput={TextField}
                    label="Stock Amount (L)"
                    value={stockAmounts[fuel.no] || ""}
                    onValueChange={(values) => {
                      const rawValue = values.value; // raw unformatted value

                      // Validate for up to 8 digits before decimal and up to 2 digits after decimal
                      if (/^\d{0,8}(\.\d{0,2})?$/.test(rawValue)) {
                        handleStockChange(index, rawValue);
                      }
                    }}
                    onInput={(e) => {
                      const value = e.target.value;

                      // Prevent further input if length exceeds 8 digits before decimal
                      if (!/^\d{0,8}(\.\d{0,2})?$/.test(value)) {
                        e.target.value = value.slice(0, -1); // trim the last character
                      }
                    }}
                    isNumericString
                    thousandSeparator
                    decimalScale={2}
                    decimalSeparator="."
                    fixedDecimalScale={true}
                    disabled={!isEditable[index]}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      style: {
                        color: "#000000",
                        fontFamily: "Poppins, sans-serif"
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        backgroundColor: "#ffffff",
                        color: "#000000"
                      },
                      "& .MuiOutlinedInput-input": {
                        color: "#000000"
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000000"
                      },
                      "& .MuiFormLabel-filled": {
                        top: -8
                      },
                      "& .MuiFormLabel-asterisk": {
                        color: "#000000"
                      }
                    }}
                  />
                </Box>
                <NumericFormat
                  customInput={TextField}
                  label="Sales Amount (L)"
                  value={salesAmounts[fuel.no] || ""}
                  onValueChange={(values) => {
                    const rawValue = values.value; // raw unformatted value

                    // Validate for up to 8 digits before decimal and up to 2 digits after decimal
                    if (/^\d{0,8}(\.\d{0,2})?$/.test(rawValue)) {
                      handleSalesChange(index, rawValue);
                    }
                  }}
                  onInput={(e) => {
                    const value = e.target.value;

                    // Prevent further input if length exceeds 8 digits before decimal
                    if (!/^\d{0,8}(\.\d{0,2})?$/.test(value)) {
                      e.target.value = value.slice(0, -1); // trim the last character
                    }
                  }}
                  isNumericString
                  thousandSeparator
                  decimalScale={2}
                  decimalSeparator="."
                  fixedDecimalScale={true}
                  disabled={!isEditable[index]}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    style: {
                      color: "#000000",
                      fontFamily: "Poppins, sans-serif"
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      backgroundColor: "#ffffff",
                      color: "#000000"
                    },
                    "& .MuiOutlinedInput-input": {
                      color: "#000000"
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000000"
                    },
                    "& .MuiFormLabel-filled": {
                      top: -8
                    },
                    "& .MuiFormLabel-asterisk": {
                      color: "#000000"
                    }
                  }}
                />
                <NumericFormat
                  customInput={TextField}
                  label="Received Stock Amount (L)"
                  value={receivedStockAmounts[fuel.no] || ""}
                  // onChange={(e) =>
                  //   handleReceivedStockChange(index, e.target.value)
                  // }
                  onValueChange={(values) => {
                    const rawValue = values.value; // raw unformatted value

                    // Validate for up to 8 digits before decimal and up to 2 digits after decimal
                    if (/^\d{0,8}(\.\d{0,2})?$/.test(rawValue)) {
                      handleReceivedStockChange(index, rawValue);
                    }
                  }}
                  onInput={(e) => {
                    const value = e.target.value;

                    // Prevent further input if length exceeds 8 digits before decimal
                    if (!/^\d{0,8}(\.\d{0,2})?$/.test(value)) {
                      e.target.value = value.slice(0, -1); // trim the last character
                    }
                  }}
                  isNumericString
                  thousandSeparator
                  decimalScale={2}
                  decimalSeparator="."
                  fixedDecimalScale={true}
                  disabled={!isEditable[index]}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    style: {
                      color: "#000000",
                      fontFamily: "Poppins, sans-serif"
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      backgroundColor: "#ffffff",
                      color: "#000000"
                    },
                    "& .MuiOutlinedInput-input": {
                      color: "#000000"
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000000"
                    },
                    "& .MuiFormLabel-filled": {
                      top: -8
                    },
                    "& .MuiFormLabel-asterisk": {
                      color: "#000000"
                    }
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 700,
                    textAlign: "center",
                    marginTop: 2
                  }}
                >
                  Last Edited: {lastEdited[fuel.no] && lastEdited[fuel.no] !== "Not yet edited" ? dayjs(lastEdited[fuel.no]).tz("Asia/Colombo").format("YYYY-MM-DD HH:mm:ss") : "Not yet edited"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSaveClick}
          sx={{
            backgroundColor: "#0078C1",
            "&:hover": {
              backgroundColor: "#005F9E"
            },
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            mt: 2,
            mb: 5,
            textTransform: "none"
          }}
        >
          Save All
        </Button>
      </Box>
    </Container>
  );
};

export default Dashboard;
