import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import NavBar from "./components/shared-components/NavBar";
import HomeScreen from "./components/scenes/SplashScreen/HomeScreen";
import LoginPage from "./components/scenes/UserManagement/LoginPage";
import Dashboard from "./components/scenes/Dashboard/Dashboard";
import OrderRequest from "./components/scenes/OderRequest/OrderRequest";
import ProfilePage from "./components/scenes/UserManagement/ProfilePage";
import ResetPassword from "./components/scenes/UserManagement/ResetPassword";
import OrderHistory from "./components/scenes/OrderHistory/OrderHistory";
import PrivateRoute from "../src/utils/privateRoutes"; 
import useInactivityTimeout from "../src/hooks/inactivityTimeout";
import ErrorPage from "./components/shared-components/404ErrorPage";
import InternalErrorPage from "./components/shared-components/500ErrorPage";

function App() {
  const [orders, setOrders] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useInactivityTimeout(480000);

  const addOrder = (order) => {
    setOrders([order, ...orders]);
  };

  const isAuthenticated = !!localStorage.getItem("token");

  const NavbarPaths = ["/order", "/history", "/dashboard", "/profile", "*"];

  const handleAutoLogout = () => {
    const now = new Date();
    const nextLogoutTime = new Date();

    // Set the logout time to 12:30 AM today or the next day if current time is past 12:30 AM
    nextLogoutTime.setHours(12, 30, 0, 0);

    // If it's already past 12:30 AM, set the logout time to 12:30 AM the next day
    if (now > nextLogoutTime) {
      nextLogoutTime.setDate(now.getDate() + 1);
    }

    const msUntilLogout = nextLogoutTime.getTime() - now.getTime();

    // Set a timeout for the logout
    const logoutTimeout = setTimeout(() => {
      if (isAuthenticated) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }, msUntilLogout);

    // Optionally check every minute to handle logout if user is still authenticated around 12:30 AM
    const checkInterval = setInterval(() => {
      const now = new Date();
      if (now.getHours() === 12 && now.getMinutes() === 30) {
        if (isAuthenticated) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      }
    }, 60000); 

    // Cleanup function
    return () => {
      clearTimeout(logoutTimeout);
      clearInterval(checkInterval);
    };
  };


  useEffect(() => {
    if (isAuthenticated) {
      handleAutoLogout();
    }
  }, [isAuthenticated]);
  return (
    <div className="App">
      {/* Show NavBar only if the user is authenticated and not on the login, reset, or home page */}
      {isAuthenticated && NavbarPaths.includes(location.pathname) && <NavBar />}
      {/* {window.location.pathname !== "/" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/reset" && <NavBar />} */}
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/order"
          element={
            <PrivateRoute>
              <OrderRequest addOrder={addOrder} />
            </PrivateRoute>
          }
        />
        <Route
          path="/history"
          element={
            <PrivateRoute>
              <OrderHistory orders={orders} />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<ErrorPage />} />

        <Route path="/error" element={<InternalErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
