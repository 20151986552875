import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Container, Typography, TextField, Button, Box, Grid, Paper, IconButton, InputAdornment } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import "@fontsource/poppins"; // Import the Poppins font
import { Tabs, Tab } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const autofillStyle = css`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-text-fill-color: #ffffff !important;
  }
`;

const ProfilePage = () => {
  const [user, setUser] = useState({});
  const [banks, setBanks] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(""); // State for error message
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [tabIndex, setTabIndex] = useState(0); // State to manage tab index

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }

      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/user/details/${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const data = response.data?.data;

        if (data) {
          setUser(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("An error occurred while fetching user details.", error);
      }
    };

    const fetchBankDetails = async () => {
      const token = localStorage.getItem("token");
      const userID = localStorage.getItem("userID");

      if (!token || !userID) {
        window.location.href = "/login";
        return;
      }
      try {
        const response = await axios.get(`https://dealerdemo.unitedpetroleumlanka.com/be/api/bank/bank/${userID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const data = response.data?.data;

        if (data) {
          setBanks(data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("An error occurred while fetching bank details.", error);
      }
    };

    fetchBankDetails();
    fetchUserDetails();
  }, []);

  const handlePasswordChange = async () => {
    // Validate new password
    const passwordValidation = (password) => {
      const hasLetter = /[a-zA-Z]/.test(password);
      const hasDigit = /\d/.test(password);
      return {
        isValid: password.length >= 8 && hasLetter && hasDigit,
        message: !hasLetter || !hasDigit ? "Password must contain both letters and digits." : password.length < 8 ? "Password must be at least 8 characters long." : ""
      };
    };

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    if (oldPassword !== oldPassword) {
      setError("Old password is wrong!");
      return;
    }

    const { isValid, message } = passwordValidation(newPassword);
    if (!isValid) {
      setError(message);
      return;
    }

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userID");

    try {
      const response = await axios.put(
        "https://dealerdemo.unitedpetroleumlanka.com/be/api/user/changePassword",
        { oldPassword, newPassword, userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );

      const data = response.data?.data;

      if (data) {
        setOpenSuccessModal(true);
        setTimeout(() => {
          navigate("/login"); // Use navigate function here
        }, 1000);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError(""); // Clear error message on success
      }
    } catch (error) {
      console.error("An error occurred while changing the password.", error);
    }
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const isFormValid = oldPassword && newPassword && confirmPassword;

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  // Toggle password visibility
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <Container sx={{ mt: 5, fontFamily: "Poppins, sans-serif", marginTop: 18 }}>
      <Paper sx={{ padding: 3, marginBottom: 2, backgroundColor: "#CEEDFF" }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="profile tabs">
          <Tab label="User Details" />
          <Tab label="Bank Details" />
        </Tabs>
        <Box sx={{ paddingTop: 2 }}>
          {tabIndex === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontFamily: "Poppins, sans-serif" }}>
                  <strong>User ID:</strong> {user.userID}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontFamily: "Poppins, sans-serif" }}>
                  <strong>Name:</strong> {capitalizeFirstLetter(user.name)}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                  <strong>Email:</strong> {user.email}
                </Typography>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontFamily: "Poppins, sans-serif" }}>
                  <strong>Location:</strong> {capitalizeFirstLetter(user.location)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {tabIndex === 1 && (
            <Grid container spacing={2}>
              {banks.length > 0 ? (
                banks.map((bank, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" sx={{ fontFamily: "Poppins, sans-serif" }}>
                        <strong>Virtual Account Number:</strong> {bank.virtualBankNo}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" sx={{ fontFamily: "Poppins, sans-serif" }}>
                        <strong>Account:</strong> {bank.bankAccountNo}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1" sx={{ fontFamily: "Poppins, sans-serif" }}>
                  No bank details available.
                </Typography>
              )}
            </Grid>
          )}
        </Box>
      </Paper>

      <Box sx={{ marginTop: 4 }}>
        <Typography
          variant="h6"
          sx={{
            marginBottom: 2,
            color: "#02344D",
            fontFamily: "Poppins, sans-serif"
          }}
        >
          <strong>Change Password</strong>
        </Typography>
        <Box mb={2} position="relative">
          <Typography
            variant="subtitle2"
            sx={{
              color: "#02344D",
              position: "absolute",
              top: -8,
              fontSize: 14,
              padding: "0 4px",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            Old Password
          </Typography>
          <TextField
            required
            margin="normal"
            placeholder="Old Password"
            type={showOldPassword ? "text" : "password"} // Toggle type here
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            fullWidth
            InputLabelProps={{
              style: { color: "#02344D", fontFamily: "Poppins, sans-serif" }
            }}
            InputProps={{
              style: {
                color: "#02344D",
                backgroundColor: "transparent",
                fontFamily: "Poppins, sans-serif"
              },
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: "#02344D" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowOldPassword} edge="end">
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "13px",
                backgroundColor: "transparent",
                "& fieldset": { borderColor: "#02344D" },
                "&:hover fieldset": { borderColor: "#02344D" },
                "&.Mui-focused fieldset": { borderColor: "#02344D" },
                fontFamily: "Poppins, sans-serif"
              }
            }}
            css={autofillStyle}
          />
        </Box>

        <Box mb={2} position="relative">
          <Typography
            variant="subtitle2"
            sx={{
              color: "#02344D",
              position: "absolute",
              top: -8,
              fontSize: 14,
              padding: "0 4px",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            New Password
          </Typography>
          <TextField
            required
            margin="normal"
            placeholder="New Password"
            type={showNewPassword ? "text" : "password"} // Toggle type here
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            InputLabelProps={{
              style: { color: "#02344D", fontFamily: "Poppins, sans-serif" }
            }}
            InputProps={{
              style: {
                color: "#02344D",
                backgroundColor: "transparent",
                fontFamily: "Poppins, sans-serif"
              },
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: "#02344D" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowNewPassword} edge="end">
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "13px",
                backgroundColor: "transparent",
                "& fieldset": { borderColor: "#02344D" },
                "&:hover fieldset": { borderColor: "#02344D" },
                "&.Mui-focused fieldset": { borderColor: "#02344D" },
                fontFamily: "Poppins, sans-serif"
              }
            }}
            css={autofillStyle}
          />
        </Box>

        <Box mb={2} position="relative">
          <Typography
            variant="subtitle2"
            sx={{
              color: "#02344D",
              position: "absolute",
              top: -8,
              fontSize: 14,
              padding: "0 4px",
              fontFamily: "Poppins, sans-serif"
            }}
          >
            Confirm Password
          </Typography>
          <TextField
            margin="normal"
            placeholder="Confirm Password"
            type={showConfirmPassword ? "text" : "password"} // Toggle type here
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            InputLabelProps={{
              style: { color: "#02344D", fontFamily: "Poppins, sans-serif" }
            }}
            InputProps={{
              style: {
                color: "#02344D",
                backgroundColor: "transparent",
                fontFamily: "Poppins, sans-serif"
              },
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: "#02344D" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "13px",
                backgroundColor: "transparent",
                "& fieldset": { borderColor: "#02344D" },
                "&:hover fieldset": { borderColor: "#02344D" },
                "&.Mui-focused fieldset": { borderColor: "#02344D" },
                fontFamily: "Poppins, sans-serif"
              }
            }}
            css={autofillStyle}
          />
        </Box>

        <Button
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            borderRadius: "7px",
            backgroundColor: "#0078C1",
            textTransform: "none",
            color: "#FFFFFF",
            width: "150px",
            height: "50px",
            fontFamily: "Poppins, sans-serif",
            "&:hover": {
              backgroundColor: "#1f3544"
            }
          }}
          onClick={handlePasswordChange}
          disabled={!isFormValid}
        >
          Save Password
        </Button>
        {error && (
          <Typography variant="body2" sx={{ color: "red", mt: 2 }}>
            {error}
          </Typography>
        )}
      </Box>
      <Modal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            borderRadius: 2,
            backgroundColor: "#10153F",
            color: "#FFFFFF",
            boxShadow: 24,
            p: 4,
            textAlign: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 2
            }}
          >
            <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
            <Typography variant="h6" color="success" sx={{ textAlign: "center" }}>
              Password changed!
            </Typography>
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
};

export default ProfilePage;
