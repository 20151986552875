import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically navigate after 2 seconds
    const timer = setTimeout(() => {
      navigate("/login");
    }, 3000);

    // Clear timeout if the component unmounts before the timeout is completed
    return () => clearTimeout(timer);
  }, [navigate]);

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <Container
      maxWidth={false}
      onClick={handleClick}
      sx={{
        backgroundColor: "#10153F",
        color: "#FFFFFF",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <img
          src="/united-petroleum-logo.png"
          alt="United Petroleum"
          style={{ width: "200px", maxWidth: "100%" }}
        />
      </Box>
    </Container>
  );
};

export default HomePage;
