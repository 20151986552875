// import Cookies from "js-cookie";
// import {Outlet , Navigate} from "react-router-dom";

// const useAuth = () =>{
//   // const token = Cookies.get("accessToken");
//   const token = sessionStorage.getItem("accessToken")
//   const hasCheckedIn = sessionStorage.getItem("hasCheckedIn");
//   if(token){
//     return {isAuthenticated: true, hasCheckedIn: hasCheckedIn === "true"};
//   }else{
//     return {isAuthenticated: false, hasCheckedIn: false};
//     }
// }

// const PrivateRoutes = () => {
//   const auth = useAuth();
//   if (!auth.isAuthenticated) {
//     return <Navigate to="/" />;
//   } else if (!auth.hasCheckedIn) {
//     return <Navigate to="/login" />;
//   } else {
//     return <Outlet />;
//   }
// }

// export default PrivateRoutes;

import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  return token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
